import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Home from './Home';
import About from './About';
import Contact from './Contact';
import Services from './Services';
import Logo from './logo.jpeg';
import Name from './name.jpg';
import Main from'./Main';

function App() {
  const [isHomeHovered, setIsHomeHovered] = useState(false);
  const [isServicesHovered, setIsServicesHovered] = useState(false);
  const [isContactHovered, setIsContactHovered] = useState(false);

  const linkStyle = {
    textDecoration: 'none', // Remove underline
    fontSize: '18px', // Set font size
    padding: '10px', // Adjust padding based on your preference
    borderRadius: '5px', // Add border radius for rounded corners
  };

  const activeLinkStyle = {
    color: 'white',
    backgroundColor: '#242AC5',
  };

  const lineStyle = {
    borderBottom: '1px solid lightgray', // Horizontal line style
    height: '1px',
    width: '100%',
  };

  const verticalLineStyle = {
    borderRight: '1px solid lightgray', // Vertical line style
    height: '80px', // Adjust height based on your logo height
    margin: '0 20px', // Adjust margin based on your preference
  };

  return (
    <Router>
      <Container>
        <Row>
          <Col>
            <Navbar bg="light" data-bs-theme="light" style={{ justifyContent: 'space-between' }}>
              <Container className="text-center">
                <Navbar.Brand as={Link} to="/">
                  <Row>
                    <Col>
                      <img
                        src={Logo}
                        alt="Logo"
                        width="80"
                        height="80"
                        className="d-inline-block align-top"
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <img
                        src={Name}
                        alt="Brand"
                        width="120"
                        height=""
                        className="d-inline-block align-top"
                      />
                    </Col>
                  </Row>
                </Navbar.Brand>
                <div style={verticalLineStyle}></div>
                <Nav className="me-auto d-flex ms-5">
                  <Nav.Link
                    as={Link}
                    to="/Services"
                    style={{ ...linkStyle, ...(isHomeHovered && activeLinkStyle) }}
                    onMouseEnter={() => setIsHomeHovered(true)}
                    onMouseLeave={() => setIsHomeHovered(false)}
                  >
                    HOME
                  </Nav.Link>
                  <Nav.Link
  as={Link}
  to="/Contact"
  style={{
    ...linkStyle,
    ...(isContactHovered && activeLinkStyle),
    marginLeft: 'auto',
    marginRight: '100px', // Adjust margin based on your preference
  }}
  onMouseEnter={() => setIsContactHovered(true)}
  onMouseLeave={() => setIsContactHovered(false)}
>
  CONTACT
</Nav.Link>

                </Nav>
              </Container>
            </Navbar>
            <br />
          </Col>
        </Row>

        <Routes>
          <Route path="/" element={<Services/>} />
          <Route path="/about" element={<About />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/Services" element={<Services />} />
         
        </Routes>
      </Container>
    </Router>
  );
}

export default App;
