
// export default Home;
import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import image1 from './img/OIP.jpeg';
import image2 from './img/twist.jpg';
import image3 from './img/infra.jpg';
import image4 from './img/supermarket.jpeg';
import image5 from './img/foodprocessing.jpg';
import image6 from './img/ai.jpeg';
import image7 from './img/roads.jpeg';
import image8 from './img/global.png';

const Home = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
  };

  // Array of image paths and corresponding text
  const images = [
    { src: image1, text: 'Oil and Gas' },
    { src: image2, text: 'Construction' },
    { src: image3, text: 'Infrastructure' },
    { src: image4, text: 'FMCG' },
    { src: image5, text: 'Food Processing' },
    { src: image6, text: 'IT & ITES' },
    { src: image7, text: 'Automobile / Automotive' },
    { src: image8, text: 'Banking' },
  ];

  // Styles for the text
  const textStyle = {
    fontWeight: 'bold',
    fontFamily: 'Arial, sans-serif',
    fontSize: '18px',
  };

  // Styles for the images
  const imageStyle = {
    height: '300px', // Set a fixed height for all images
    margin: '0 auto',
  };

  // Render the component
  return (
    <div style={{ textAlign: 'center' }}>
      <h1>Services and Industries</h1>
      {/* Slider component with settings */}
      <Slider {...settings}>
        {images.map((item, index) => (
          <div key={index} style={{ textAlign: 'center' }}>
            {/* Render each image with fixed height */}
            <img src={item.src} alt={`Slide ${index + 1}`} style={imageStyle} />
            {/* Text below each image */}
            <p style={textStyle}>{item.text}</p>
          </div>
        ))}
      </Slider>
    </div>
  );
};

// Export the component
export default Home;
